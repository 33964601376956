<template>
  <div>

    <div class="row">
      <div class="col-12 mb-3">
        <transition name="slide-fade" appear>
          <a class="btn btn-link btn-border mr-2" title="Regresar a pantalla anterior" @click="gotoOrdenes">
            <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'arrow-left', }" class="icon btn-icon" />
            Regresar
          </a>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <label class="h4 align-sub" v-if="ordenData.Proveedor" :key="ordenData.Proveedor">{{ ordenData.Proveedor }}</label>
        </transition>
      </div>
    </div>

    <orden-data :provId="ProvId" :ordenId="OrdenId" @getData="getOrden($event)"/>

    <div class="row">
      <transition name="slide-fade" appear>
        <div class="col-12 mt-2" >
          <div class="table-responsive site-table-responsive">
            <b-button class="btn-detail mb-2" @click="openModalAddPeriodo()">
              <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'plus', }" class="icon btn-icon"/> Agregar periodo</b-button>

            <div v-if="loading" class="empty-image center-content">
              <span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Cargando...</span>
            </div>
            <vue-good-table
            v-else
            styleClass="vgt-table condensed site-table2"
            :columns="columns"
            :rows="rows">
              <div slot="emptystate">
                <div class="empty-image">
                  <img src="@/assets/empty.svg" alt="Empty">
                  <p class="empty-description">Sin datos</p>
                </div>
              </div>
              <template slot="table-row" slot-scope="props">
                <div>
                  <span v-if="props.column.field == 'Progreso'" class="pointer"
                    v-b-popover.hover.html="`<strong>Progreso: </strong>${props.row.ProgresoMensual}%<br>El Porcentaje de progreso esta en función de los documentos sin marca de riesgo hasta el periodo ${ordenData.MaxPeriodo} y los meses previos`">
                    <bar-progress :value="props.row.ProgresoMensual"></bar-progress>
                  </span>
                  <span v-else-if="props.column.field == 'Acciones'">
                    <b-button class="btn-detail mr-1">
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'download', }" class="icon btn-icon"/> Descargar</b-button>
                    <b-button class="btn-detail" v-if="$eliminar(['Periodos'])" @click="showDeleteOrden(props.row.Periodo)">
                      <font-awesome-icon :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/></b-button>
                  </span>
                  <span v-else-if="props.column.field === 'Periodo'" class="pointer"
                    v-b-popover.hover.html="`<strong>Fecha:</strong> ${props.row.Fecha}<br><strong>Autor:</strong> ${props.row.Autor}`">
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                  <span v-else-if="props.column.field == `Estatus_${props.column.label}`"
                    @click="openFilesLoader(props.row[`Id_${props.column.label}`], props.column.description, props.row.Periodo)">
                    <span v-if="!props.row[`Estatus_${props.column.label}`]" class="icon-msg">
                      <font-awesome-icon class="icon"
                        :icon="{ prefix: 'fa', iconName: 'check-circle'}"
                        :style="{color: '#168736'}"/>
                    </span>
                    <span v-else class="btn btn-xxs btn-light-xs">
                      <font-awesome-icon class="icon"
                          :icon="{ prefix: 'fa', iconName: 'file-pdf'}"
                          :style="{color: 'red'}"/> {{ props.formattedRow[props.column.field] }}
                    </span>
                  </span>
                  <span v-else class="ellipsis-300">
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </transition>
    </div>

    <b-modal
      id="bv-modal-periodo" size="sm" centered hide-footer content-class="site-modal"
      :header-text-variant="'light'">
      <template #modal-title>
        NUEVO PERIODO
      </template>
      <form class="form-site inner" @submit.prevent="agregarPeriodo()">
        <div class="form-row form-row-select">
          <div class="form-holder form-holder-2">
            <fieldset>
              <legend>Periodo</legend>
              <date-picker
                type="month"
                class="text-center"
                :placeholder="'MM-AAAA'"
                :clearable="false"
                :format="'MM-YYYY'"
                :formatter="formatedPeriodo"
                :disabled-date="disabledBeforeFechaOrden"
                v-model="periodo">
                <template v-slot:header="{ emit }">
                  <button class="mx-btn mx-btn-text" @click="emit(new Date())">* MES ACTUAL</button>
                </template>
              </date-picker>
            </fieldset>
          </div>
        </div>
        <button type="submit" class="btn btn-block btn-action mb-2" block>
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
          <font-awesome-icon v-if="!loading" :icon="{ prefix: 'fa', iconName: 'save', }" class="icon btn-icon" />Agregar periodo
        </button>
      </form>
    </b-modal>

  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import { VueGoodTable } from 'vue-good-table';
import OrdenData from '@/components/OrdenData.vue';
import BarProgress from '@/components/BarProgress.vue';
import SimpleVueValidation from 'simple-vue-validator';
import moment from 'moment';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    OrdenData,
    VueGoodTable,
    BarProgress,
  },
  props: [
    'ProvId',
    'OrdenId',
  ],
  data() {
    return {
      loading: false,
      loadingUp: false,
      archivoListIdSelected: '',
      periodoSelected: '',
      periodoDeConsulta: this.$formatDateMonth(moment().subtract(1, 'M').toDate()),
      periodo: moment().toDate(),
      filesList: {},
      formatedPeriodo: {
        stringify: (date) => this.$formatDateMonth(date) || '',
      },
      ordenData: {},
      columns: [],
      rows: [],
    };
  },
  validators: {
    periodo(value) { return Validator.value(value).required('Campo obligatorio'); },
  },
  mounted() {
    this.getFilesList();
  },
  methods: {
    openModalAddPeriodo() {
      this.$bvModal.show('bv-modal-periodo');
    },
    agregarPeriodo() {
      this.loading = true;
      apiClient.post(`/orden/${this.OrdenId}/periodo`, {
        ProvId: this.ProvId,
        periodo: this.$formatDate(this.periodo),
      })
        .then(() => {
          this.loading = false;
          this.$bvModal.hide('bv-modal-periodo');
          this.getFilesList();
        })
        .catch(() => { this.loading = false; });
    },
    getFilesList() {
      this.loading = true;
      apiClient.get(`/orden/${this.OrdenId}/listado`, {
        params: {
          ProvId: this.ProvId,
          periodo: this.$formatDate(this.periodo),
        },
      })
        .then((res) => {
          this.rows = res.data.Rows;
          // Configuramos las columnas, ya que se agregan automaticamente algunas desde la BDD
          this.columns = [
            {
              label: 'Periodo',
              field: 'Periodo',
              thClass: 'text-center',
              tdClass: 'text-center',
            },
          ];

          res.data.Columns.forEach((column) => {
            this.columns.push(
              {
                label: column.Alias,
                field: `Estatus_${column.Alias}`,
                listId: column.ArchivoListId,
                description: column.Nombre,
                thClass: 'text-center',
                tdClass: 'text-center',
                tooltip: column.Nombre,
              },
            );
          });

          this.columns.push(
            {
              label: 'Progreso',
              field: 'Progreso',
              sortable: false,
              thClass: 'text-center',
              tdClass: 'text-center',
            },
            {
              label: 'Acciones',
              field: 'Acciones',
              sortable: false,
              thClass: 'text-center',
              tdClass: 'text-center',
              width: '150px',
            },
          );
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showDeleteOrden(periodo) {
      this.$bvModal.msgBoxConfirm('Si continua, se eliminará el Periodo seleccionado y todos sus archivos adjuntos.', {
        title: 'Eliminar Periodo',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si, Eliminar',
        cancelTitle: 'Cancelar',
        headerClass: 'text-light',
        footerClass: 'bg-light p-2',
        hideHeaderClose: true,
        centered: true,
      })
        .then((confirm) => {
          if (confirm) this.deleteFileList(periodo);
        })
        .catch(() => {});
    },
    deleteFileList(periodo) {
      this.loading = true;
      apiClient.delete(`/orden/${this.OrdenId}/periodo`, {
        data: {
          ProvId: this.ProvId,
          periodo,
        },
      })
        .then(() => {
          this.getFilesList();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openFilesLoader(archivoPeriodoId, descripcion, periodoSelected) {
      this.$router.push({
        name: '/ordenes/detalle/cargar',
        params: {
          ProvId: `${this.ProvId}`,
          OrdenId: `${this.OrdenId}`,
          archivoPeriodoId,
          title: descripcion,
          periodo: periodoSelected,
        },
      }).catch(() => {});
    },
    disabledBeforeFechaOrden(date) {
      const fechaOc = this.$mxToDate(this.ordenData.Fecha);
      const ocmonth = fechaOc.getMonth() + 1;
      const ocyear = fechaOc.getFullYear();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return (month < ocmonth && year === ocyear) || year < ocyear;
    },
    getOrden(data) {
      this.ordenData = data;
    },
    gotoOrdenes() {
      this.$router.push({ name: '/ordenes', params: { ProvId: `${this.ProvId}`, NomProv: `${this.ordenData?.Proveedor}` } }).catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
